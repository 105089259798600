.file-preview-list {

}

	.file-preview {
		position: relative;
		background-color: #F0F0F0;
		margin-bottom: 1rem;
		border-radius: 7px;
		color: #5F5F5F;
	}


	.file-preview-header {
		padding: 1.3rem;
		border-bottom: 1px solid #D3D3D3;
		display: flex;
		align-items: center;
	}


		.file-preview-icon-container {
			margin-right: 1rem;
		}

			.file-preview-icon {
				position: relative;
				background-color: #ffffff;
				border: 1px solid #D3D3D3;
				border-radius: 100rem;
				width: 4rem;
				height: 4rem;

				svg {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -52%);
					fill: #C6C6C6;
					width: 2rem;
					height: 2rem;
				}
			}

			.file-preview--booked .file-preview-icon {
				border: 2px solid $primary-color;
				svg {
					fill: $primary-color;
				}
			}

			.file-preview--completed .file-preview-icon {
				border: 2px solid $primary-color;
				svg {
					fill: $primary-color;
				}
			}



			.file-preview-label {
				font-size: .8rem;
				text-transform: uppercase;

				// Fancy labeling
				position: absolute;
				top: .5rem;
				right: -.25rem;
				background-color: $primary-color;
				color: #fff;
				padding: .5rem;
				font-weight: 600;

				svg {
					position: relative;
					width: .8rem;
					height: .8rem;
					fill: #fff;
					top: 1px;
					margin-right: .25rem;
				}
			}

			.file-preview--booked .file-preview-label {
				background-color: #659A67;
			}


			.file-preview-title-block {
				flex-grow: 1;
			}


				.file-preview-pretitle {
					font-size: 14px;
					text-transform: uppercase;
					margin-bottom: .2rem;
				}

				.file-preview-title {
					margin: 0 0 .25rem 0;
					font-size: 1.25rem;
					font-weight: 600;
					user-select: none;
					margin-bottom: .35rem;

					span {
						cursor: pointer;
					}

					.title-icon {
						position: relative;
						display: inline-block;
						width: 1rem;
						height: 1rem;
						margin-left: .5rem;
						top: 2px;
						svg {
							fill: #5F5F5F;
						}
					}
				}


				

				.file-preview-subtitle {
					font-size: .85rem;
				}


				.file-preview-actions {
					display: flex;
					margin-top: .5rem;
				}

					.file-preview-action {
						margin-right: .75rem;
						font-size: 14px;
						font-weight: 600;
						color: $primary-color;
						cursor: pointer;
					}

			.file-preview-open-trigger-container {

			}

				.file-preview-open-trigger {
					position: relative;
					top: 1rem;
					padding: 1rem;
					margin-right: -1rem;
					cursor: pointer;
					user-select: none;
				}

					.file-preview-open-trigger-icon {
						width: .625rem;
						height: .625rem;
						border-bottom: 2px solid #707070;
						border-right: 2px solid #707070;
						transform: rotate(45deg);
					}

		.file-preview-body {
			background-color: #fff;
			border-right: 1px solid #F0F0F0;
			border-left: 1px solid #F0F0F0;
			display: none;
		}

			.file-preview-row {
				display: flex;
				justify-content: space-between;	
				padding: 1rem;
				border-bottom: 1px solid #F0F0F0;
			}

		.file-preview--open .file-preview-body {
			display: block;
		}

		.counts-list-item-footer {
			//border-top: 1px solid #D3D3D3;
		}


			.file-preview-row {
				display: flex;
				justify-content: space-between;	
				padding: 1rem;
				border-bottom: 1px solid #F0F0F0;
			}


		.file-preview-footer-ekstra {
			border-top: 1px dashed #D3D3D3;
		}


		.file-preview-comments {
			padding: 1rem;
			textarea {
				border: 1px solid #D6D6D6;
				border-radius: 4px;
				padding: 1rem;
				width: 100%;
				height: 5rem;
				resize: none;
			}
			p {
				margin: 0;
			}
		}

			.file-preview-comments-heading {
				font-size: 1rem;
				margin-bottom: .5rem;
				margin-top: 0;
			}
