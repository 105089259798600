.breadcrumbs {
	text-transform: uppercase;
	font-weight: 600;
	color: #CECECE;
	font-size: .75rem;
	margin-bottom: 2rem;
}



.title-field {
	margin-bottom: 1.5rem;
}

	.title-field-label {
		display: block;
		text-transform: uppercase;
		font-weight: 600;
		color: #5F5F5F;
		margin-bottom: .5rem;
	}


	.title-field-input-container {
		padding-bottom: .5rem;
		border-bottom: 1px solid #D6D6D6;
	}

		.title-field-input {
			width: 100%;
			border: none;
			font-size: 1.875rem;
			font-weight: 600;
			padding-left: 0;
			outline: none;

			&::placeholder {
				font-style: italic;
				color: #C7C7C7;
				font-weight: 400;
			}

			&:focus {
				background-color: #F2F9FF;
			}
		}


.standard-field-section {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

}


	.field-section-header {
		width: 100%;
		border-bottom: 1px solid #D6D6D6;
		font-size: 1rem;
		font-weight: 600;
		color: #5F5F5F;
		text-transform: uppercase;
		padding: .5rem 0;
		margin-bottom: 1.125rem;
		margin-top: 2rem;
	}

.standard-field-clear {
	width: 100%;
	height: 0;
}

.standard-field {
	width: 45%;
	flex-grow: 0;
	margin-bottom: 1.5rem;

	textarea {
		width: 100%;
		display: block;
		font-size: 1rem;
		padding: .5rem;
		border: 1px solid #D3D3D3;
		border-radius: 5px;
	}

	input:disabled {
		background-color: #EAEAEA;
	}
}

	.standard-field--disabled {
		opacity: .4;
	}

	.standard-field--wide {
		width: 100%;
	}

	.standard-field-label-container {
		display: flex;
		align-items: center;
		margin-bottom: .5rem;
	}

		.standard-field-label {
			display: block;
			text-transform: uppercase;
			font-weight: 600;
			color: #5F5F5F;
		}

		.standard-field-label-tooltipper {

		}

			.standard-field-label-tooltip-icon {
				position: relative;
				z-index: 2;
				background-color: #BFBFBF;
				width: 16px;
				height: 16px;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 10rem;
				font-size: .75rem; 
				margin-left: .25rem;

				&:before {
					content: attr(data-text); /* here's the magic */
					position:absolute;
					 
					/* vertically center */
					top:100%;

					 
					/* move to right */
					left:50%;
					transform: translateX(-50%);
					margin-top:12px; /* and add a small left margin */
					 
					/* basic styles */
					width:150px;
					padding:10px;
					border-radius:10px;
					background:#BFBFBF;
					text-align:center;

				  	display: none;
				}

				&:hover:before, &:hover:after {
				  display:block;
				}

				&:after {
				  content: "";
				  position:absolute;
				  
				  /* position tooltip correctly */
				  left:50%;
				  transform: translateX(-50%);
				  
				 
				  /* vertically center */
				  top:4px;

				  /* the arrow */
				  border:16px solid #BFBFBF;
				  border-color: transparent transparent #BFBFBF transparent;
				  
				  display: none;
				}
			}


	.standard-field-input-container {
		background-color: #D95454;
		border-radius: 5px;

	}

		.standard-field-input {
			width: 100%;
			font-size: 1rem;
			padding: .5rem;
			border: 1px solid #D3D3D3;
			border-radius: 5px;
		}

		.standard-field-input-error {
			padding: .5rem .75rem;
			background-color: #D95454;
			color: #FFFFFF;
			border-radius: 5px;
			font-size: .8rem;
			//text-transform: uppercase;

		}


	.standard-readonly-field-input-container {
		border-radius: 5px;

	}

		.standard-readonly-field-input {
			width: 100%;
			font-size: 1rem;
			padding: .5rem;
			border: 1px solid #D3D3D3;
			border-radius: 5px;
		}


.block-select {
	margin: 0;
	padding: 0;
	list-style: none;
}

	.block-select-item {
		display: inline-block;
		margin-right: 1rem;
		margin-bottom: 1rem;
		user-select: none;
		input {
			position: absolute;
			opacity: 0;
			cursor: pointer;
			height: 0;
			width: 0;

			&:checked ~ .block-select-item-element {
				background-color: $primary-color;
				border-color: $primary-color;
				color: #fff;
			}
		}
	}

	.block-select-item-element {
		background-color: #fff;
		color: #606060;
		border: 1px solid #D3D3D3;
		padding: .5rem .75rem;
		border-radius: 5px;
		cursor: pointer;
	}

	.block-select-item--selected {
		background-color: $primary-color;
		border-color: $primary-color;
		color: #fff;
	}



.table-fields {
	width: 100%;
	max-width: 30rem;
	border: 1px solid #D6D6D6;
	border-collapse: collapse;
	color: #5F5F5F;
	th, td {
		border: 1px solid #D6D6D6;
		padding: .625rem;
	}
	th {
		text-align: left;
		font-weight: 600;
		
	}
}

.table-fields--wide {
	max-width: 100%;
}


	.table-field-input-column {
		width: 6rem;
	}

	.table-field-input {
		//border: none;
		width: 100%;
	}

	.table-field-suffix {
		width: 3.5rem;
		background-color: #F2F2F2;
	}

	.table-field-input-error {
		color: #D95454;
		font-size: 12px;
		font-style: italic;
		margin-left: .5rem;
	}


.checkbox-field-input-container {
	list-style: none;
	margin: 0;
	padding: 0;
	input {
		margin-right: .5rem;
	}
}

	.checkbox-field-input-item {
		margin-bottom: .5rem;
	}


.field-section-submit {
	margin-top: 2rem;
	left: 0;
}

	.standard-submit-update {
		font-style: italic;
		font-size: .8rem;
		color: #8B8B8B;

	}

.fixed-action-bar {
	position: fixed;
	z-index: 5;
	right: 0;
	width: calc(100% - 592px);
	bottom: 0;
	background-color: rgba(255,255,255,.9);
	padding: 1rem;
	text-align: center;
	box-shadow: 0px 0px 14px rgba(0,0,0,.16);

	transition: transform .2s ease;
	transform: translateY(0%);

	.button {
		margin: 0 .25rem;
	}
}

.fixed-action-bar--wide {
	width: calc(100% - 304px);

	.button {
		margin: 0 .25rem;
	}
}

.fixed-action-bar--hidden {
	transform: translateY(100%);
}


.fixed-action-bar--success {
	background-color: #21B264;
	color: #fff;
}

.fixed-action-bar--saving {
	background-color: $primary-color;
	color: #fff;
}

.form-padding {
	padding-bottom: 4rem;
}

.form-button-group {

	.button {
		margin-right: .5rem;
	}
}

