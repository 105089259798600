.recipe-table {
	width: 100%;
	table-layout: auto;
	th {
		text-align: left;
		font-weight: .875rem;
		color: #5F5F5F;
		font-weight: 600;
		padding-bottom: .25rem;
	}

	td {
		padding-top: .25rem;
		padding-bottom: .25rem;
		//border: 1px solid black;
	}

}

	.recipe-table-row {
	}

		.recipe-table-row--deleted {
			.recipe-table-row--deleted-text {
				background-color: $light-gray;
				color: rgba(0,0,0,.7);
				padding: .6rem;	
				border-radius: 4px;
			}

			.recipe-table-row-deleted-text {

			}
		}

		.recipe-table-ingredient {
			width: 60%;
			padding-right: .5rem;

			.has_error {
				background-color: red;
			}
		}

		.recipe-table-measurement {
			min-width: 7.5rem;
			width: 15%;
			padding-right: .5rem;
		}

		.recipe-table-price {
			min-width: 7.5rem;
			width: 15%;
			padding-right: .5rem;

			input:disabled,
			input:read-only {
				background-color: #F2F2F2;
			}
		}

		.recipe-table-remove {
			width: 3rem;
			padding-right: .5rem;
		}

			.recipe-table-remove-button {
				border: 1px solid #D3D3D3;
				background-color: #fff;
				border-radius: 5px;
				width: 2.25rem;
				height: 2.25rem;
				color: #606060;	
				cursor: pointer;
				&:hover {
					background-color: #F7F7F7;
				}
			}

		.recipe-table-drag-handle {

			width: 1rem;
			height: 100%;
			align-items: center;
			svg {
				width: 1rem;
				fill: #959595;
			}
		}


		.field-without-suffix {
			position: relative;
			display: flex;

			input {
				width: 100%;
				font-size: 1rem;
				padding: .5rem;
				border: 1px solid #D3D3D3;
				border-radius: 5px;
			}
		}

		.field-with-suffix {
			position: relative;
			display: flex;

			input {
				width: 100%;
				//max-width: 6rem;
				font-size: 1rem;
				padding: .5rem;
				border: 1px solid #D3D3D3;
				border-radius: 5px 0 0 5px;
			}
		}

			.field-suffix {
				background-color: #F2F2F2;
				border: 1px solid #D3D3D3;
				border-left: 0;
				border-radius: 0 5px 5px 0;
				padding: .5rem .75rem;
				top: 0;
				right: 0;
				display: block;
				font-weight: 300;
				white-space: nowrap;
			}


			.recipe-table-add-row {
				width: 100%;
				border-radius: 5px;
				font-size: 1.5rem;
				color: #606060;
				background-color: #fff;
				border: 1px dashed #BEBEBE;
				cursor: pointer;
				margin-top: .5rem;
				margin-bottom: 2rem;
				padding: .25rem 0;
				&:hover {
					background-color: #F7F7F7;
				}
			}


// Count unit variation
.recipe-table-count_unit_name {
	width: 70%;
	padding-right: .5rem;
}

.recipe-table-count_unit_amount {
	min-width: 10rem;
	width: 30%;
	padding-right: .5rem;
}


.repeatable-units-table {
	margin-bottom: 1rem;
}