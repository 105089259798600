.order-form-details-field-section {
	margin-bottom: 1rem;

	@media only screen and (min-width: $layout-change) {
		display: flex;	
		justify-content: space-between;
	}
}

	.order-form-details-field {
	
		@media only screen and (min-width: $layout-change) {
			flex-grow: 1;
			margin-right: 1rem;
			&:nth-of-type(3) {
				width: 20rem;
				max-width: 20rem;
				margin-right: 0;
			}
		}
	}


.order-form-item-field-section {
	height: 20rem;
	@media only screen and (min-width: $layout-change) {
		display: flex;
		height: 35rem;
	}
}

	.order-form-line-items {
		flex-grow: 1;
		margin-right: 1rem;
		height: 100%;
		display: flex;
		flex-direction: column;
	}

		.order-form-line-items-no-items {
			position: relative;
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			p {
				font-style: italic;
			}
		}


		.order-form-line-items-table-container {
			position: relative;
			background-color: #fff;
			color: #5F5F5F;
			border: 1px solid #D6D6D6;
			border-radius: 4px;
			overflow: scroll;
			flex-grow: 1;
		}


			.order-form-line-items-table {
				border-collapse: collapse;
				table-layout: fixed;
				width: 100%;
				td, th {
					border-bottom: 1px solid #D6D6D6;
					text-align: left;
					padding: 1rem;

				}
			}

			.order-table-row--hide {
				display: none;
			}

				.order-table-field-block {

				}

					.order-table-field-block-title {
						font-weight: bold;
					}

					.order-table-column-product-count {
						width: 15rem;
					}


	.order-form-checkout {
		width: 100%;
		@media only screen and (min-width: $layout-change) {
			width: 20rem;
			flex-shrink: 0;
			height: 100%;
			display: flex;
			flex-direction: column;
		}
	}

		.order-form-cart {
			background-color: #fff;
			flex-grow: 1;
			color: #5F5F5F;
			border: 1px solid #D6D6D6;
			border-radius: 4px;
			margin-bottom: 1rem;
			display: flex;
			flex-direction: column;
			overflow: scroll;
		}

			.order-form-cart-header {
				background-color: #F2F2F2;
				padding: 1rem;
				flex-grow: 0;
				h3 {
					margin-bottom: 0;
				}
			}

			.order-form-heading {
				font-size: 1rem;
				font-weight: 600;
				color: #5F5F5F;
				margin: 0;
				margin-bottom: .5rem;
			}

				.cart-counter {
					display: inline-block;
					// width: 1.2rem;
					// height: 1.2rem;
					// background-color: #fff;
					// border: 1px solid gray;
					// border-radius: 1rem;
					// display: inline-flex;
					// align-items: center;
					// justify-content: center;
					// margin-left: .25rem;
					// font-size: 12px;
				}


			.order-form-cart-body {
				padding: 1rem;
				flex-grow: 1;
				overflow: scroll;
			}

				.order-form-cart-line-item {
					padding-bottom: 1rem;
					display: flex;
					justify-content: space-between;
				}

					.order-form-cart-line-item-main {
						
					}

						.order-form-cart-line-item-name {
							font-weight: 600;
							padding-right: 2rem;
						}

						.order-form-cart-line-item-meta {
							
						}

					.order-form-cart-line-item-price {
						font-weight: 600;
						white-space: nowrap;
					}

				.order-form-cart-footer {
					flex-grow: 0;
					padding: 1rem;
				}

					.order-form-cart-footer-total {
						border-top: 1px solid #B7B7B7;
						padding-top: .5rem;
						display: flex;
						justify-content: space-between;
						font-weight: 600;
					}

						.order-form-cart-footer-total-label {

						}

		.order-form-comments {
			margin-bottom: 1rem;


			textarea {
				border: 1px solid #D6D6D6;
				border-radius: 4px;
				padding: 1rem;
				width: 100%;
				height: 5rem;
				resize: none;
			}
		}

		.order-form-submit {
			width: 100%;
		}

/**
 * Order form modal
 */


.order-form-modal {
	min-width: 45rem;

	.file-preview-icon {
		width: 3rem;
		height: 3rem;
		background-color: #52A756;
		border: none;
		svg {
			width: 1.4rem;
			height: 1.4rem;
			fill: #fff;
		}
	}

	.file-preview-pretitle {
		font-weight: 600;
		color: #52A756;
	}
}

	.order-form-modal-header {
		padding: 1.5rem;
		border-bottom: 1px solid #D8D8D8;
	}

	.order-form-modal-body {
		padding: 1.5rem;
		height: 27rem;
		overflow: scroll;
	}


	.order-form-modal-footer {
		padding: 1.5rem;
		border-top: 1px solid #D8D8D8;
		text-align: center;
		.button {
			margin: 0 .5rem;
			width: 10rem;
		}
	}
























