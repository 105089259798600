.counts-overview-list {
	margin-top: 2rem;
}

	.count-overview-table {
		background-color: #F0F0F0;
		margin-bottom: .75rem;
		border-radius: 7px;
		width: 100%;
		border-collapse: collapse;
		a {
			color: inherit;
			text-decoration: none;
		}

		td {
			padding: 1rem;
		}

		&.count-overview-table--open {

			.count-overview-item-category-row,
			.count-overview-item-footer-row {
				display: table-row;
			}
		}
	}

	.count-overview-item-title-field {

	}

		.count-overview-item-title-block{
			color: #454545;
			cursor: pointer;
			user-select: none;
		}

			.count-overview-item-pretitle {
				font-size: .8rem;
				margin-bottom: .2rem;
			}	

			.count-overview-item-title {
				font-size: 1.1rem;
			}

			.count-overview-item-meta-block{
				color: #454545;
				cursor: pointer;
				user-select: none;
				display: none;
				@media only screen and (min-width: 40rem) {
					display: block;
				}

			}

				.count-overview-item-meta-title {
					
				}

				.count-overview-item-meta-value {
					
				}	


	.count-overview-item-value-field {
		width: 8rem;
	}

	.count-overview-item-actions {
		width: 5rem;
		text-align: right;
		padding-left: 0 !important;
		svg {
			width: 1.25rem;
			path {
				fill: #454545;
			}
		}

		a, span {
			margin-left: .75rem;
		}
	}

	.counts-delete-icon {
		cursor: pointer;
	}

.count-overview-item-category-row {
	background-color: #fff;
	border: 1px solid #F0F0F0;
	display: none;
	td:nth-last-of-type(1),
	td:nth-last-of-type(2) {
		background-color: #F8F8F8;
	}
}

.count-overview-item-footer-row {
	display: none;
}

/////// NEEWWWWWW

.counts-list-container {

}

	.counts-list-item {
		position: relative;
		background-color: #F0F0F0;
		margin-bottom: 1rem;
		border-radius: 7px;
		color: #5F5F5F;
	}

		.counts-list-item-header {
			padding: 1.3rem;
			border-bottom: 1px solid #D3D3D3;
			display: flex;
			align-items: center;
		}

			.counts-list-item-icon-container {
				margin-right: 1rem;
			}

				.counts-list-item-icon {
					position: relative;
					background-color: #ffffff;
					border: 1px solid #D3D3D3;
					border-radius: 100rem;
					width: 3.125rem;
					height: 3.125rem;

					svg {
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -52%);
						fill: #C6C6C6;
						width: 1.75rem;
						height: 1.75rem;
					}
				}

				.counts-list-item--completed .counts-list-item-icon {
					border: 2px solid $primary-color;
					svg {
						fill: $primary-color;
					}
				}


			.counts-list-item-title-block {
				flex-grow: 1;
			}

				.counts-list-item-pretitle {
					font-size: .8rem;
					text-transform: uppercase;

					// Fancy labeling
					position: absolute;
					top: .5rem;
					right: -.25rem;
					background-color: $primary-color;
					color: #fff;
					padding: .5rem;
					font-weight: 600;

					svg {
						position: relative;
						width: .8rem;
						height: .8rem;
						fill: #fff;
						top: 1px;
						margin-right: .25rem;
					}
				}

				.counts-list-item-title {
					margin: 0 0 .25rem 0;
					font-size: 1.25rem;
					font-weight: 600;
					user-select: none;

					span {
						cursor: pointer;
					}

					.title-icon {
						position: relative;
						display: inline-block;
						width: 1rem;
						height: 1rem;
						margin-left: .5rem;
						top: 2px;
						svg {
							fill: #5F5F5F;
						}
					}

				}

				.counts-list-item-subtitle {
					font-size: .85rem;
				}

			.counts-list-item-open-trigger-container {

			}

				.counts-list-item-open-trigger {
					position: relative;
					top: 1rem;
					padding: 1rem;
					margin-right: -1rem;
					cursor: pointer;
					user-select: none;
				}

					.counts-list-item-open-trigger-icon {
						width: .625rem;
						height: .625rem;
						border-bottom: 2px solid #707070;
						border-right: 2px solid #707070;
						transform: rotate(45deg);
					}

			.counts-list-item-trash-container {

			}
				.counts-list-item-trash-trigger {
					position: relative;
					top: 1rem;
					padding: 1rem;
					margin-right: -1rem;
					cursor: pointer;
					user-select: none;
				}

					.counts-list-item-trash-icon {
						width: 1rem;
						height: 1rem;
					}

		.counts-list-item-body {
			background-color: #fff;
			border-right: 1px solid #F0F0F0;
			border-left: 1px solid #F0F0F0;
			display: none;

		}

		.count-list-item--open .counts-list-item-body {
			display: block;
		}

		.counts-list-item-footer {

			//border-top: 1px solid #D3D3D3;
		}


			.counts-item-row {
				display: flex;
				justify-content: space-between;	
				padding: 1rem;
				border-bottom: 1px solid #F0F0F0;
			}
