.button {
	display: inline-block;
	background-color: $primary-color;
	color: $secondary-color;

	text-decoration: none;
	text-align: center;

	padding: 0.75rem 1rem;
	border: none;
	border-radius: 5px;
	cursor: pointer;

	font-weight: 600;

	&.button--light {
		background-color: #fff;
		color: $primary-color;
	}

	&.button--medium {
		min-width: 10rem;
	}

	&.button--wide {
		min-width: 17.75rem;
	}

	&.button--warning {
		background-color: #EB2D2D;
	}

	&.button--ghost {
		background-color: transparent;
		border: 1px solid $primary-color;
		color: $primary-color;
	}

	&:disabled {
		opacity: 0.2;
		cursor: default;
	}

}


.button-group {

	.button {
		margin-right: 1rem;
		&:last-of-type { margin-right: 0; }
	}
}
