.filter-box {
	border: 1px solid #D6D6D6;
	border-radius: 5px;
	padding: 1rem 1.5rem 1.25rem 1.5rem;
	margin-bottom: 1rem;	
	display: flex;
}

	.filter-box-field {

	}

	.filter-box-field--search {
		width: 20rem;
	}

	.filter-box-field--filler {
		flex-grow: 1;
	}

	.filter-box-field--account {

		width: 15rem;
	}

