.danger-zone {
	margin-top: 4rem;
}

.danger-zone-section-heading {
	position: relative;
	text-align: center;
	margin-bottom: 1rem;
	&:before {
		content: '';
		display: block;
		position: absolute;
		top: 50%;
		left: 0;
		width: 100%;
		border-bottom: 1px solid gray;
	}

	.danger-zone-section-title {
		position: relative;
		z-index: 2;
		display: inline-block;
		background-color: #fff;
		padding: 0 1rem;
		font-size: 1rem;
		text-transform: uppercase;
		font-weight: 600;
		color: #797979;
	}
}

.danger-zone-content {
	border: 1px solid #EB2D2D;
	border-radius: 5px;	
	padding: 1.4rem;
	color: #606060;
	p {
		font-size: .875rem;
		color: inherit;
	}

	h4 {
		color: inherit;
		margin: .5rem 0;
	}
	ul {
		margin: .5rem 0;

		li {
			margin: .25rem 0;
		}
	}


	a {
		color: inherit;
		text-decoration: none;
	}

	.button {
		margin-top: 1.5rem;
	}
}

	.danger-zone-title {
		font-size: 1rem;
		color: #EB2D2D;
		margin-top: 0;
	}