.navigation-container {

}

	.navigation-bar-mobile {
		border-bottom: 1px solid #D6D6D6;
		background-color: #fff;
		height: 3.25rem;
		display: flex;
		align-items: center;
		@media only screen and (min-width: $layout-change) {
			display: none;
		}
	}

		
		.navigation-bar-mobile-trigger-container {
			height: 100%;
			width: 3.25rem;
			margin-right: .5rem;
		}

			.navigation-bar-mobile-trigger {
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				cursor: pointer;
			}

				.navigation-bar-mobile-trigger-line-wrapper {
					width: 1.875rem;
				}

					.navigation-bar-mobile-trigger-line {
						position: relative;
						background-color: #101010;
						height: 4px;
						margin-bottom: 4px;
						transition: all .2s ease;

						&:before {
							content: '';
							position: absolute;
							width: 100%;
							height: 100%;
							background-color: #101010;
							transition: all .1s ease;
						}
					}

					.navigation-open .navigation-bar-mobile-trigger-line {
						&:nth-of-type(1) {
							display: none;
						}

						&:nth-of-type(2) {
							transform: rotate(45deg);
							&:before {
								transform: rotate(90deg);
							}
						}

						&:nth-of-type(3) {
							display: none;
						}
					}




		.navigation-bar-mobile-heading {
			margin-top: -4px;
			font-weight: 600;
			font-size: 1.1rem;
		}



		.navigation {
			position: absolute;
			z-index: 2;
			height: calc(100vh - 6.5rem);
			width: 100%;
			background-color: #fff;
			padding: 1rem;
			display: none;

			@media only screen and (min-width: $layout-change) {
				position: relative;
				width: 15rem;
				height: 100%;
				display: block;
				padding: 2rem 1.875rem;
			}
		}

		.navigation-open .navigation {
			display: block;
		}


			.menu-items-container {
				color: #8B8B8B;
				font-size: 14px;
			}

				.menu-items-container > ul {
					list-style: none;
					margin: 0;
					padding: 0;
				}

					.menu-items-container > ul > li {
						margin-bottom: 1.5rem;

						> a {
							display: flex;	
							align-items: center;
							text-transform: uppercase;
							font-weight: bold;
							text-decoration: none;
							color: inherit;
							cursor: pointer;

							outline: none;
							// &:focus {
							// 	color: #5DBAD6 !important;
							// }

						}
					}

						.menu-item-icon {
							width: 1.375rem;
							height: 1.375rem;
							margin-right: .625rem;

							path {
								fill: #8B8B8B;
							}
						}


					.menu-items-container > ul > li ul {
						list-style: none;
						margin: 0;
						padding: 0;
						padding-left: 2rem;
					}

						.menu-items-container > ul > li ul > li{
							margin-top: .75rem;

							> a {
								display: flex;	
								align-items: center;
								text-decoration: none;
								color: #8B8B8B;
								outline: none;

								// &:focus {
								// 	color: #5DBAD6 !important;
								// }
							}
						}

						.current-menu-item {
							color: $primary-color !important;
							> .menu-item-icon path {
								fill: $primary-color;
							}

							> .menu-item-label {
								color: $primary-color;
							}
						}
							
						button.menu-item-anchor {
							@extend a;
							padding: 0;
							border: none;
							outline: none;
						}



						// .submenu-container {
						// 	height: 0;
						// 	overflow-x: hidden;
						// 	transition: height .2s ease;
						// }

						// .submenu-container-open .submenu-container {
						// 	height: 5.25rem;
						// }


						.submenu-container--hidden {
							display: none;
						}

						.menu-item-anchor--disabled {
							opacity: .4;
							pointer-events: none;
						}
