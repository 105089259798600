.intro-section {
	height: calc(100vh - 6.5rem);
	display: flex;
	align-items: center;
	justify-content: center;
	@media only screen and (min-width: $layout-change) {
		height: 100vh;
	}
	
}

	.intro-section-content {
		max-width: 40rem;
		text-align: center;
	}

		.intro-section-image {

			svg {
				width: 14rem;
				path {
					fill: #D9D9D9;
				}
			}
		}


		.intro-section-title {
			color: #A8A8A8;
			font-size: 1.75rem;
			margin: .875rem 0;
		}

		.intro-section-description {
			color: #A8A8A8;
			font-size: 1.125rem;
			margin: .875rem 0 1.75rem 0;
			max-width: 29rem;
		}

