/**
 * Datepicker
 */
 .date-picker-container {
	margin-top: .5rem; 
	position: relative;
	display: flex;
	justify-content: space-between;
	z-index: 20;
 }

	.date-picker-container-arrow {
		background-color: #fff;
		border: 1px solid #d9d9d9;
		width: 2.5rem;
		flex-grow: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		border-radius: 4px;
	}

	.date-picker-container-arrow-inner {
	}


	.datepicker-select {
		position: relative;
	}

	.date-picker-container .datepicker-select {
		width: 49%;
		margin-right: .5rem;
		margin-left: .5rem;
	}

		
		.datepicker-select-input {
			display: flex;
			justify-content: space-between;
			background-color: #fff;
			border: 1px solid #d9d9d9;
			color: hsl(0,0%,20%);
			padding: .6rem .75rem;
			font-size: 1rem;
			font-weight: 400;
			cursor: pointer;
			border-radius: 4px;
			
		}


			 	.datepicker-select-body {
			 		flex-grow: 1;
			 	}

			 	.datepicker-select-toggler {
			 		padding: 0 .2rem 0 .75rem;
			 		/*border-left: 1px solid rgba(0,0,0,.3);*/
			 	}


				.arrow {
					border: solid rgba(0,0,0,.2);
					border-width: 0 2px 2px 0;
					display: inline-block;
					padding: 4px;
					
				}
				
				.up {
				  	transform: rotate(-135deg);
				  	position: relative;
					top: 2px;
				}

				.down {
				  	transform: rotate(45deg);
				  	position: relative;
				  	top: -2px;
				}

				.left {
				  	transform: rotate(-225deg);
				  	position: relative;
					top: 0px;
					right: -2px;
				}

				.right {
				  	transform: rotate(-45deg);
				  	position: relative;
					top: 0px;
					right: 2px;
				}


		.datepicker-select-container {
			position: absolute;
			background-color: #fff;
			box-shadow: 0px 0px 7px rgba(0,0,0,.1);
			z-index: 1;
			top: calc(100% + .25rem);
			left: 0;
			border: 1px solid #d9d9d9;
		}

		.datepicker-select:nth-of-type(3) .datepicker-select-container {
			background-color: #fff;
			box-shadow: 0px 0px 7px rgba(0,0,0,.1);
			position: absolute;
			z-index: 1;
			top: calc(100% + .25rem);
			right: 0;
			left: auto;
			border: 1px solid #d9d9d9;
		}