body.page-template-login-page {
	background-color: $primary-color;
}


	.login-split-view {


		

		@media only screen and (min-width: 60rem) {
			display: flex;
		}

	}


		.login-split-view-main {
				
				@media only screen and (min-width: 60rem) {
					flex-grow: 1;
					height: 100vh;
				}

		}

			.login-split-view-main-content {
				color: $secondary-color;
				padding: 1.75rem;
				
				@media only screen and (min-width: 60rem) {
					margin-left: 6rem;
					margin-top: 3.75rem;
				}
				
				
			}

				.login-logo {
					margin-top: 2rem;
					margin-bottom: 4rem;
					width: 2.7rem;
					svg path {
						fill: $secondary-color;
					}
				}

				.login-title {
					font-size: 1.5rem;
					margin-bottom: 2.5rem;
					max-width: 25rem;
					@media only screen and (min-width: 45rem) {
						font-size: 2rem;
					}
				}

				.login-description {
					margin-bottom: 5rem;
					max-width: 25rem;
					@media only screen and (min-width: 45rem) {
						margin-bottom: 4rem;
						max-width: 27rem;

					}
					// p { font-weight: 100; }
				}


		.login-split-view-side {
			background-color: $primary-color;
			@media only screen and (min-width: 45rem) {
				width: 35rem;
			}
		}
