.settings-item-list {
	margin-top: 2rem;
}

	.settings-items {
		padding: 0;
		margin: 0;
		list-style: none;
	}

		.settings-item {
			background-color: #F0F0F0;
			margin-bottom: .75rem;
			padding: 1rem;
			border-radius: 7px;
			display: flex;
			//justify-content: space-between;
			align-items: center;
			a {
				color: inherit;
				text-decoration: none;
			}
		}

			.settings-item-number {
				background-color: #fff;
				width: 1rem;
				height: 1rem;
				line-height: 1rem;
				font-size: 12px;
				text-align: center;
				margin-right: 1rem;
			}

			.settings-item-title {
			}

			.settings-item-edit {
				width: 1.25rem;

				svg {
					margin-bottom: -5px;
				}

			}