.app-container {

	// @media only screen and (min-width: $layout-change) {
	// 	display: flex;
	// }
}

	.app-sidebar {
		@media only screen and (min-width: $layout-change) {
			position: fixed;
			z-index: 10;
			height: 100vh;
			width: 19rem;
			display: flex;
			border-right: 1px solid #D5D5D5;
		}
	}

	.app-content {
		@media only screen and (min-width: $layout-change) {
			padding-left: 19rem;
		}
		
	}

		.app-panel {
			display: none;
			background-color: #fff;
			overflow: hidden;
			flex-direction: column;
			height: calc(100vh - 6.5rem);
						
			@media only screen and (min-width: $layout-change) {
				display: flex;
				position: fixed;
				height: 100%;
				width: 18rem;
				border-right: 1px solid #D5D5D5;
			}
		}


		.navigation-open .page-content {
			display: none;
			@media only screen and (min-width: $layout-change) {
				display: block;
			}
		}


		.page-content {}

		// .mobile-show-app-panel .page-content {
		// 	display: none;
		// 	@media only screen and (min-width: $layout-change) {
		// 		display: block;
		// 	}
		// }

		.app-panel ~ .page-content {
			@media only screen and (min-width: $layout-change) {
				padding-left: 18rem;
			}
		}

			.page-content-inner {
				position: relative;
				padding: 2.25rem 1rem;
			}

			.page-content-inner-centering {
				max-width: 45rem;
				margin: 0 auto;
			}

			.page-content-inner-centering--wide {
				max-width: 75rem;
				margin: 0 auto;
			}

			
				.page-content-header {
					display: flex;
					justify-content: space-between;
				}


					.page-title {
						margin: 0;
						margin-right: 1rem;
					}
