.brand-bar {
	background-color: $primary-color;
	height: 3.25rem;
	display: flex;
	justify-content: center;
	align-items: center;

	@media only screen and (min-width: $layout-change) {
		height: 100%;
		width: 4rem;
		flex-direction: column;
		justify-content: start;
		padding-top: 1.25rem;
	}
}

	.brand-bar-logo {
		width: 1.5rem;

		path {
			fill: #f2eddf;
		}
	}