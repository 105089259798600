.spinner {
  //position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  //width: 100vw;
  width: 100%;
  background-color: #fff;
  // top: 0;
  // bottom: 0;
  // left: 0;
  // right: 0;
  img {

  }
}


/**
 * Fragment: Spinner;
 */

 .spinner-height-wrapper {
  height: 15rem;
 }

 .spinner-page-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
 }

.spinner-centered {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.spinner {
  width: 30px;
  height: 30px;
  margin: 0 auto;
  background-color: #4D80FF;
  border-radius: 100%;  
  -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
  animation: sk-scaleout 1.0s infinite ease-in-out;
}

.spinner--white .spinner {
  background-color: #FFFFFF;
}

@-webkit-keyframes sk-scaleout {
  0% { -webkit-transform: scale(0) }
  100% {
    -webkit-transform: scale(1.0);
    opacity: 0;
  }
}

@keyframes sk-scaleout {
  0% { 
    -webkit-transform: scale(0);
    transform: scale(0);
  } 100% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
    opacity: 0;
  }
}