/**
 * Basic styling
 *
 * $primary-color: #4D80FF;
 * $dark-gray: #6E6E6E;
 */


/**
 * Vendor
 */
 @import 'vendor/normalize';


 /**
  * Basics
  */
 // @import 'basics/mixins';
 @import 'basics/colors';
 //@import 'basics/fonts';
 @import 'basics/typography';
 // @import 'basics/forms';
 

/**
  * Fragments (component parts)
  */
@import 'fragments/button';
@import 'fragments/loading';

// /**
//  * Modules (components)
//  */
@import 'modules/item-list';
@import 'modules/settings-item-list';
@import 'modules/count-item-list';
@import 'modules/file-preview';
@import 'modules/intro-section';
@import 'modules/danger-zone';
@import 'modules/recipe-table';
@import 'modules/info-card';
@import 'modules/filter-box';
@import 'modules/count-table';
@import 'modules/modal';
@import 'modules/datepicker-select';

/**
 * Partials
 */
@import 'partials/layout';
@import 'partials/login';
@import 'partials/brand-bar';
@import 'partials/navigation';
@import 'partials/forms';

@import 'partials/order-form';



.stock-reconciliation-table {
	
		white-space: noWrap;
}