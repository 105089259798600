	.item-list-searchbar {
		padding: 1.625rem;
		box-shadow: 0px 0px 14px rgba(0,0,0,.16);
	}

		.item-list-searchfield-container {
			position: relative;
			border-bottom: 1px solid #606060;


		}

			.item-list-searchfield-icon {
				position: absolute;
				left: 0;
				height: 100%;
				width: 1rem;
				display: flex;
				justify-content: center;
				align-items: center;
				svg {
					width: 1rem;
					height: 1rem;
					path { fill: #606060; }
				}
			}

			.item-list-search-input {
				width: 100%;
				border: none;
				font-size: 1rem;
				padding: .5rem;
				padding-left: 1.5rem;
				color: #606060;
				outline: 0;

				&::placeholder {
					font-style: italic;
					color: #C7C7C7;
					font-weight: 400;
				}

				&:focus {
					background-color: #F2F9FF;
				}
			}


	.item-list-scrollable {
		overflow: scroll;
		-webkit-overflow-scrolling: touch;
	}

		.item-list {
			list-style: none;
			margin: 0;
			padding: .75rem .5rem 4rem .5rem;		
		}

			.item-list-item {
				margin-bottom: .375rem;

				a {
					display: block;
					background-color: #EFEFEF;
					border-radius: 4px;
					padding: 1.25rem;
					color: #707070;
					text-decoration: none;
				}
			}

			.item-list-item--active {
				a {
					background-color: $primary-color;
					color: #fff;
				}
				
			}

				@keyframes loading {
				  to {
				    background-position:
				      	250% 0,
				        20px 20px, /* title */
				        20px 40px;  /* card bg */
				    ;
				  }
				}

				.item-list-item-skeleton {
					display: block;
					background-color: #EFEFEF;
					border-radius: 4px;
					padding: 1.25rem;
					color: #707070;
					height: 73px;
					text-decoration: none;

					background-repeat: no-repeat;
					background-image:
						/* Animation */					   
						linear-gradient(
					    	90deg, 
					    	rgba(#EFEFEF, 0) 0, 
					   		rgba(#EFEFEF, .8) 50%, 
					    	rgba(#EFEFEF, 0) 100%
					    ), 
					    /* Ttitle */
					    linear-gradient(#D8D8D8 16px, transparent 0),
					    /* Description */
					    linear-gradient(#D8D8D8 12px, transparent 0);
					background-size:
					 	/* Animation */
					 	150px 73px, 
					 	/* Title */
					    200px 40px, 
					     /* Description */
					    110px 12px; 

					background-position:
						/* Animation */
						-150% 0,
						/* Title */
					    20px 20px, 
					    /* Description */
					    20px 40px; 
					animation: loading 1.5s infinite;

				}

				// asdfads
				.item-list-item:nth-of-type(2) .item-list-item-skeleton {animation-delay: .2s;}
				.item-list-item:nth-of-type(3) .item-list-item-skeleton {animation-delay: .3s;}
				.item-list-item:nth-of-type(4) .item-list-item-skeleton {animation-delay: .4s;}
				.item-list-item:nth-of-type(5) .item-list-item-skeleton {animation-delay: .5s;}
				.item-list-item:nth-of-type(6) .item-list-item-skeleton {animation-delay: .6s;}
				.item-list-item:nth-of-type(7) .item-list-item-skeleton {animation-delay: .7s;}
				.item-list-item:nth-of-type(8) .item-list-item-skeleton {animation-delay: .8s;}
				.item-list-item:nth-of-type(9) .item-list-item-skeleton {animation-delay: .9s;}
				.item-list-item:nth-of-type(10) .item-list-item-skeleton {animation-delay: 1s;}


				.item-list-item-title {
					font-size: .9375rem;
					font-weight: 600;
					margin-bottom: .125rem;
				}

				.item-list-item-subtitle {
					font-size: .75rem;
				}



		.item-list-primary-button-container {
			position: absolute;
			width: 100%;
			bottom: 0;
			padding: 1rem .75rem;	
		}
			.item-list-primary-button {
				background-color: $primary-color;
				color: #fff;
				border: none;
				width: 100%;
				border-radius: 7px;
				padding: .75rem 0;
				cursor: pointer;
			}