.ReactModal__Overlay {
	z-index: 100;
}

	.modal-content {
		padding: 2rem;
		max-width: 38rem;
		color: #5F5F5F;
	}

		.modal-title {
			font-weight: 500;
			margin: 0;
		}	

		.modal-message {

		}

		.modal-button {
			width: 8rem;
		}