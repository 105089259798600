.info-card {
	padding: 1.75rem;
	background-color: $primary-color;
	color: #fff;
	margin-bottom: 2rem;
	border-radius: 5px;
}
	
	.info-card-title {
		font-size: 1.375rem;
		font-weight: 600;
		margin-top: 0;
		margin-bottom: .5rem;
	}

	.info-card-subtitle {
		margin-top: -.5rem;
		margin-bottom: 1rem;
	}

	.info-card-description {
		max-width: 20rem;
		margin-bottom: 1.5rem;
		p:first-of-type {
			margin-top: 0;
		}
	}

	.info-card-nutrition-info {
		
	}

		.info-card-nutrition-info-header {
			border: 1px solid #fff;
			padding: 1rem;
			border-bottom: 0;
			h3 {
				margin: 0;
				font-weight: 600;
				font-size: 1rem;
				font-style: italic;
			}
		}

		.info-card-nutrition-info-content {
			border: 1px solid #fff;
			padding: 1rem;	
		}

			.info-card-nutrition-info-list {
				padding: 0;
				margin: 0;
				list-style: none;
			}

			.info-card-nutrition-info-item {
				display: flex;
				margin-bottom: .25rem;
				&:last-of-type {
					margin-bottom: 0;
				}

			}

				.info-card-nutrition-info-item-title {
					flex-grow: 0;
					flex-shrink: 0;
					padding-right: .5rem;
				}

				.info-card-nutrition-info-item-fill {
					flex-shrink: 1;
					overflow: hidden;
					&:before {
						content: '·······································································································································································································································';
					}
				}

				.info-card-nutrition-info-item-value {
					flex-grow: 0;
					flex-shrink: 0;
					padding-left: .5rem;
				}
