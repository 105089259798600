.count-table-container {
	border-radius: 6px;
	border: 1px solid #D6D6D6;
	margin: 1rem 0;
	color: #5F5F5F;
	overflow-x: scroll;
}

.count-table {
	width: 100%;
	border-collapse: collapse;
	
	th, td {
		height: 4rem;
		padding: 0 1rem;
		text-align: left;
		border-bottom: 1px solid #D6D6D6;
		//white-space: nowrap;
	}

	thead th {
		font-weight: 600;
		//white-space: nowrap;
	}

	
	tbody tr td:nth-last-of-type(1),
	thead tr th:nth-last-of-type(1),
	thead tr th:nth-last-of-type(2),
	tbody tr td:nth-last-of-type(2) {
		background-color: #F2F2F2;
	}

	tfoot {
		background-color: #E2E2E2;
		td {
			border-bottom: 0;
		}
	}

	tr.count-table-row-ready {
		//background-color: seagreen;
	}

	tr.count-table-row--hide {
		display: none;
	}

	td.count-table-row-checkmark {
		min-width: 2.5rem;
		padding-right: 0;
		svg {
			width: 1.5rem;
			height: 1.5rem;
			path {
				fill: #52A756;	
			}
			
			&.checkmark-warning path {
				fill: #D3B305;
			}	
		}
	}

	.total-amount-td,
	.total-value-td {
		white-space: nowrap;
	}
}


.count-table-product-title-container {
	padding: .5rem 0;
}
	.count-table-product-title {
		font-weight: 600;
		max-width: 16rem;
		// white-space: nowrap;
		// overflow: hidden;
		// text-overflow: ellipsis;
	}

	.count-table-product-subtitle {
		font-size: .875rem;
	}


.count-table-input-container {
	display: flex;
	min-width: 12rem;
	.field-suffix {
		font-size: 14px;
	}
}
	.count-table-input {
		border: none;
		border-bottom: 1px solid #9B9B9B;
		width: 5rem;
		margin-right: .5rem;
	}

	